import React from 'react';

function Admin() {
  return (
    <div className="admin">
      <a
        href="https://karenvogel731547933.wordpress.com/wp-admin/"
        target="_blank"
        rel="noopener noreferrer"
        className="btn"
      >
        Clique aqui para página de admin
      </a>
    </div>
  );
}

export default Admin;
